<template>
    <div>
        <el-dialog :title="typeName" :visible.sync="dialogVisible" width="60%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="收货人" prop="name">
                    <el-input v-model="ruleForm.realname" minlength="2" maxlength="10"></el-input>
                </el-form-item>
                <el-form-item label="收货人手机号" prop="tel">
                    <el-input v-model="ruleForm.mobile"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="省市区" prop="province">
                            <el-select v-model="ruleForm.province" placeholder="请选择省" @change="handleProvinceChange"
                                style="width: 100%;">
                                <el-option v-for="item in provinceData" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="city" label-width="15px">
                            <el-select v-model="ruleForm.city" placeholder="请选择市" @change="handleCityChange"
                                style="width: 100%;">
                                <el-option v-for="item in cityData" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="area" label-width="15px">
                            <el-select v-model="ruleForm.area" placeholder="请选择区" style="width: 100%;">
                                <el-option v-for="item in areaData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="详细地址" prop="address">
                    <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>

                <el-form-item label="默认地址" prop="default">
                    <el-switch v-model="ruleForm.default"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="danger" @click="submitForm('ruleForm')">新 增</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import areaJson from '@/assets/area.json'
import { addressCreate } from '@/api/apis'
export default {
    name: "evaluateDialog",
    data () {
        // 验证手机号的规则
        var validateMobile = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('手机号码不能为空'))
            } else if (!test.mobile(value)) {
                callback(new Error('请输入正确的手机号码'))
            } else {
                callback()
            }
        };
        return {
            dialogVisible: false,
            typeName: '新增收货地址',

            provinceData: [],
            cityData: [],
            areaData: [],
            ruleForm: {
                realname: '',
                mobile: '',
                province: '',
                city: '',
                area: '',
                address: '',
                default: 0
            },
            rules: {
                realname: [
                    { required: true, message: '请输入收货人姓名', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入收货电话', trigger: 'blur' },
                    {
                        validator: validateMobile,
                        trigger: ['change', 'blur']
                    }
                ],
                province: [
                    { required: true, message: '请选择省', trigger: 'change' }
                ],
                city: [
                    { required: true, message: '请选择市', trigger: 'change' }
                ],
                area: [
                    { required: true, message: '请选择区', trigger: 'change' }
                ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],

            }
        }
    },
    mounted () {
        this.provinceData = areaJson.provinceData;
        //this.cityData = areaJson.cityData;
        //this.areaData = areaJson.areaData;
    },
    methods: {
        handleRemove (file, fileList) {
            console.log(file, fileList)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            this.previewDialogVisible = true
        },
        // 省 change 事件 并更新 cityData
        handleProvinceChange (){
            const province = this.provinceData.find(p => p.label === this.ruleForm.province);
            this.cityData = [];
            this.areaData = [];
            this.ruleForm.city = '';
            this.ruleForm.area = '';
            if (province) {
                const cityData = areaJson.cityData
                    .filter(cityArray => cityArray.some(city => city.pid === province.value))
                    .flatMap(cityArray => cityArray);

                this.cityData = cityData;
            }
        },
        // 市 change 事件 并更新 areaData
        handleCityChange(){
            const city = this.cityData.find(p => p.label === this.ruleForm.city);
            this.areaData = [];
            this.ruleForm.area = '';
            if (city) {
                const areaData = areaJson.areaData
                    .reduce((accumulator, current) => accumulator.concat(current), [])
                    .reduce((accumulator, current) => accumulator.concat(current), [])
                    .filter(area => area.pid === city.value);
                this.areaData = areaData;
            }
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //alert('submit!')
                    addressCreate(this.ruleForm).then(res => {
                        if (res.code === 1) {
                            this.$message.success(res.msg)
                            this.dialogVisible = false
                            this.$emit('refresh')
                        }else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            })
        },
        resetForm (formName, ruleForm) {
            this.$refs[formName].resetFields()
            this.dialogVisible = false
        },
        showDialog (type, item) {
            let typeName = '新增收货地址'
            let ruleForm = {
                name: '',
                tel: '',
                province: '',
                city: '',
                area: '',
                address: '',
                default: false
            }
            if (type === 'add') {
                typeName = '新增收货地址'
            } else {
                typeName = '编辑收货地址'
                ruleForm = item
            }

            this.typeName = typeName
            this.dialogVisible = true
            this.ruleForm.name = ruleForm.name
            this.ruleForm.tel = ruleForm.tel
            this.ruleForm.province = ruleForm.province
            this.ruleForm.city = ruleForm.city
            this.ruleForm.area = ruleForm.area
            this.ruleForm.address = ruleForm.address
            this.ruleForm.default = ruleForm.default
        },
    }
}
</script>

<style lang="less" scoped>
.el-select {
    width: 100%;
}
</style>